import React from "react";

export const LetterHead = () => {
  return (
    <>
      <h1>Kyle Bennett</h1>
      <h3>Full-Stack Typescript Engineer</h3>
    </>
  );
};
